var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"elevation":"0","color":"transparent"}},[_c('audio',{staticClass:"my_audio hidden",staticStyle:{"display":"none"},attrs:{"id":"mySound","controls":"","preload":"none"}},[_c('source',{attrs:{"src":require("../../assets/new_message.mp3"),"type":"audio/mpeg"}})]),_c('v-card-title',[_vm._v(" Панель состояния "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({on, attrs}){return [_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){_vm.accessAudio = !_vm.accessAudio}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.accessAudio ? 'mdi-volume-high' : 'mdi-volume-off')+" ")])],1)]}}])},[_c('span',[_vm._v("Звуковые уведомления")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-book-account-outline","name":"Новые заказы","handler":_vm.updateState,"handler-data":"В работе","is-orders":"true","headers":[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  },
                  {
                    text: 'Срочность',
                    value: 'Urgency',
                    sortable: false
                  },
                  {
                      text: 'Загрузить',
                      value: 'AppendIcon',
                    sortable: false
                  }
              ],"history":_vm.currentOrders}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-book-account-outline","name":"В работе","handler":_vm.updateState,"handler-data":"Готово","is-orders":"true","headers":[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  },
                  {
                      text: 'Выполнено',
                      value: 'ChangeState',
                    sortable: false
                  }
              ],"history":_vm.inProcessOrders}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-book-account-outline","name":"Готово","handler":_vm.updateState,"handler-data":"Оплачен","is-orders":"true","headers":[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  },
                  {
                      text: 'Выполнено',
                      value: 'ChangeState',
                    sortable: false
                  }
              ],"history":_vm.readyOrders}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-book-account-outline","name":"Оплачено","is-orders":"true","headers":[
                  {
                      text: 'Номер заказа',
                      key: 'Order',
                      sortable: false,
                      value: '@Order'
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Сумма',
                      value: 'Amount',
                      sortable: false
                  },

                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  }
              ],"history":_vm.payedOrders}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('StatisitcCard',_vm._b({},'StatisitcCard',{
                title: 'Заказов',
                icon: 'mdi-book-account-outline',
                amount: _vm.orders,
                action: 'Подробнее...',
                link: '/orders'
},false))],1),_c('v-col',{attrs:{"cols":"4"}},[_c('StatisitcCard',_vm._b({},'StatisitcCard',{
                title: 'Оплачено',
                icon: 'mdi-credit-card-outline',
                amount: _vm.sells,
                action: 'Подробнее...',
                link: '/orders'
},false))],1),_c('v-col',{attrs:{"cols":"4"}},[_c('StatisitcCard',_vm._b({},'StatisitcCard',{
                title: 'Уникальных клиентов',
                icon: 'mdi-account',
                amount: _vm.clients,
                action: 'Подробнее...',
                link: '/orders'
},false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-cellphone","name":"Ожидают звонка","is-orders":"true","headers":[
                  {
                      text: 'Номер заказа',
                      value: '@Order',
                      sortable: false
                  },
                  {
                      text: 'Дата',
                      value: 'Datetime',
                      sortable: false
                  },
                  {
                      text: 'Номер телефона',
                      value: 'Phone',
                      sortable: false
                  }
              ],"history":_vm.waitCalls}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('OrdersHistory',{attrs:{"icon":"mdi-calendar-account","name":"Активность в админке","headers":[
                  {
                      text: 'Дата',
                      sortable: false,
                      value: 'Datetime'
                  },
                  {
                      text: 'Действие',
                      value: 'Action',
                      sortable: false
                  },
                  {
                      text: 'Имя пользователя',
                      value: 'Name',
                      sortable: false
                  }
              ],"history":_vm.journal}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
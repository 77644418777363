<template>
    <v-card elevation="0">
        <v-card-title>
            <v-icon>
                {{icon}}
            </v-icon>
            {{name}}
        </v-card-title>
        <v-data-table
                :headers="headers"
                :items="history"
                :items-per-page="5"
                :item-class="strongIfNotProceed"
            :hide-default-header="true"
                class="elevation-0"
                no-data-text="Пока пусто :)"
                :footer-props="{itemsPerPageText: 'Строк на странице'}"
        >
            <template v-slot:item.@Order="{item}">
                {{ item['@Order'] }}
                <v-tooltip top v-if="isOrders && !item.Status">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon color="red" v-on="on" v-bind="attrs">
                            mdi-exclamation
                        </v-icon>
                    </template>
                    <strong >

                        Заказ не обработан
                    </strong>
                </v-tooltip>

            </template>
            <template v-slot:item.AppendIcon="{item}">
                <v-btn icon v-on:click="downloadZip(item)">
                    <v-icon color="green darken-4" >mdi-download</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.ChangeState="{item}">
                <v-btn icon v-on:click="handler(item, handlerData)">
                    <v-icon color="green darken-4" >mdi-check</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import axios from 'axios';
    import {getURL} from "../settings";
    import {saveAs} from 'file-saver';

    export default {
        name: "OrdersHistory",
        props: ['name', 'headers', 'history', 'icon', 'isOrders', 'handler', 'handlerData'],
        data: () => {
            return {
            }
        },
        methods: {
            downloadZip(item) {
                axios.post(getURL('order/get_archive'), {
                    id: item['@Order'],

                }, {withCredentials: true, responseType:'blob'}).then(resp => {
                    console.log(resp.headers);
                    saveAs(resp.data, resp.headers["content-name"]);
                    this.handler(item, this.handlerData);
                })
            },
            strongIfNotProceed(item) {
                if(this.isOrders && !item['Status']) {
                    return 'strong';
                }
                return 'common';
            }
        }
    }
</script>

<style scoped>
    .strong {
        background-color: red;
    }
</style>

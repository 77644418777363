var render = function render(){var _vm=this,_c=_vm._self._c;return _c('kinesis-container',{staticClass:"d-flex flex-row justify-center align-center overflow-hidden",staticStyle:{"width":"100vw","height":"100vh","background":"black"}},[_c('kinesis-element',{staticStyle:{"position":"absolute"},attrs:{"strength":10}},[_c('v-img',{staticClass:"v-image__image--cover",staticStyle:{"z-index":"-5"},attrs:{"src":require("../../assets/auth/auth_background.jpg")}})],1),_vm._l((60),function(i){return _c('kinesis-element',{key:`star-20-${i}`,style:({'position': 'absolute',
                      'top': `${_vm.randomsY[i]}%`,
                       'left': `${_vm.randomsX[i]}%`,
                          'z-index': '5'}),attrs:{"strength":40}},[_c('v-icon',{staticClass:"scale-out-center",style:({'animation-delay': `${_vm.randomsAnime[i]}ms`}),attrs:{"color":"white","x-small":""}},[_vm._v("mdi-star-four-points")])],1)}),_vm._l((40),function(i){return _c('kinesis-element',{key:`star-30-${i}`,style:({'position': 'absolute',
                      'top': `${_vm.randomsY[100 + i]}%`,
                       'left': `${_vm.randomsX[100 + i]}%`,
                          'z-index': '5'}),attrs:{"strength":80}},[_c('v-icon',{staticClass:"scale-out-center",style:({'animation-delay': `${_vm.randomsAnime[ 15 + i ]}ms`}),attrs:{"color":"white","x-small":""}},[_vm._v("mdi-star-four-points")])],1)}),_vm._l((20),function(i){return _c('kinesis-element',{key:`star-40-${i}`,style:({'position': 'absolute',
                      'top': `${_vm.randomsY[200 + i]}%`,
                       'left': `${_vm.randomsX[200 + i]}%`,
                          'z-index': '5'}),attrs:{"strength":120}},[_c('v-icon',{staticClass:"scale-out-center",style:({'animation-delay': `${_vm.randomsAnime[ 30 + i ]}ms`}),attrs:{"color":"white","x-small":""}},[_vm._v("mdi-star-four-points")])],1)}),_c('v-card',{staticClass:"elevation-16 d-flex flex-column justify-space-around align-center auth__card transparent",staticStyle:{"width":"50vw","height":"50vh"},attrs:{"dark":""}},[(!_vm.name)?_c('v-skeleton-loader',{attrs:{"loading":!_vm.name,"type":"heading"}}):_c('v-card-title',{staticClass:"main-card__title text-break"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('v-form',{staticClass:"elevation-2 pa-4 my-2 rounded-l",staticStyle:{"background-color":"rgba(0,0,0,0.4)"}},[_c('v-text-field',{attrs:{"placeholder":"Логин"},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c('v-text-field',{attrs:{"type":"password","placeholder":"Пароль"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(_vm.incorrectLogin)?_c('v-card-subtitle',{staticStyle:{"color":"#ffaeae"}},[_vm._v(" Неверный логин или пароль! ")]):_vm._e(),_c('v-btn',{attrs:{"elevation":"0","block":""},on:{"click":_vm.tryAuthorize}},[_vm._v("Вход")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }